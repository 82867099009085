@font-face {
    font-family: 'DINOT';
    src: url('../public/fonts/DINOT-Bold.woff2') format('woff2'),
        url('../public/fonts/DINOT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINOT';
    src: url('../public/fonts/DINOT.woff2') format('woff2'),
        url('../public/fonts/DINOT.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINOT';
    src: url('../public/fonts/DINOT-Medium.woff2') format('woff2'),
        url('../public/fonts/DINOT-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bravest';
    src: url('../public/fonts/Bravest.woff') format('woff2'),
        url('../public/fonts/Bravest.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: red;
    transform-origin: 0%;
  }

  
  .pinBox {
    background-color: white;
    position: relative;
    overflow: hidden;
    height: 100vh;
    width: auto;
  }

  .pinSlider {
    position: relative;
  }

  .content {
    position: absolute;
  }

  /* .text {
    position: absolute;
    transform: translateY(50%);
  } */
  /* .pinSlider {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    background: white;
  } */

  /* .content {
    transform: translateY(50%);
    position: absolute;
    max-width: 100%;
    z-index: 200;
    color: white;
    z-index: 99;
  } */

  .problem {
    /* position: absolute; */
    z-index: 99;
    
  }

  .inner {
    position: relative;
    margin: 0 auto;
    max-width: 60%;
    overflow: hidden;
    padding-top: 50px;
  }

  /* Framer test */
  .container {
    /* margin: 4px auto 0;
    padding: 0 20px;
    max-width: 1080px; */
    
  }

  .sticky-wrapper {
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
  }

  .carousel {
    display: flex;
    gap: 48px;
  }

  .slide {
    width: 100vw;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

.project-partners:hover > div {
  opacity: 0.5;
}
.project-partners div:hover {
  opacity: 1;
}

/* Dots for the Results section */
.dot {
  background-color: lightgray;
}
.is-active {
  background-color: #d7c60d;
}